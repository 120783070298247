import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
  <li className="leaf">
    <Link to={props.to}>{props.children}</Link>
  </li>
)
export default ({ children }) => (
  <ul className="menu">
    <ListLink to="/teaching-tips/">Overview</ListLink>
    <ListLink to="/teaching-tips/know-your-student">Know your student</ListLink>
    <ListLink to="/teaching-tips/hints">Helpful hints</ListLink>
    <ListLink to="/teaching-tips/special-needs">Special needs</ListLink>
  </ul>
)


