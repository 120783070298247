import React from "react"
import Layout from "../../components/layout-tips"

export default () => (
  <Layout title="Teaching Tips">
    <h2>Teaching Tips</h2>
    <div className="clear-block">
      <div id="node-6" className="node">
        <div className="content clear-block">
          <p><img src="/images/043-lorelai-250x333.jpg" className="float-left" width="250"
                  height="333" alt=""/></p>
          <p>There are many factors that affect your success in any teaching situation. I want to share with you some of
            the techniques I have learned in my 45 years of teaching music, primarily piano to children from age three
            to adults. Some of the adults just knew it was impossible to ever succeed in playing an instrument
            especially the piano.</p>
          <p>Each individual in this world is unique and special; therefore, they learn on their own terms. It is
            natural to standardize learning to cover the average of all learners, but that must then be customized to
            fit the individual. The Animal Note Method is no exception to this fact of life. In fact, it was developed
            as a customization of some of the standard music education methods on the market today. It, too, must be
            customized to the individual student. This section of the wedsite will give you some suggestions and ideas.
            Hopefully they will help you creat ideas of your own. It would be terrific if you would share what works for
            you, so we can pass your ideas along to others.</p>
          <p>With the Animal Note Method of teaching beginning music, relax, be creative enjoy the success and happiness
            of your students.</p>
          <h3>Testimonial</h3>
          <p><i>I love your idea of teaching notes with animals and am so happy that you have published something like
            this. Slogans are just too hard for young students to remember and figure out. That's why I have actually
            used animal and other similar concepts in the past for the young ones. </i><br/>
            - Diana Farias</p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
